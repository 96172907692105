/* General Styles */
section {
  padding: 120px 0px 80px 0px !important;
}
.section-hero {
  padding: 60px 0px 0px 0px !important;
}
.section-title {
  text-align: center !important;
  margin-bottom: 60px !important;
}
.section-content {
  margin-bottom: 36px !important;
  margin-top: 50px !important;
}
.section-divider {
  border: 5px solid var(--secondary-color) !important;
  width: 380px !important;
  border-radius: 25px !important;
  opacity: 1 !important;
}
@media (max-width: 991.98px) {
  .section-divider {
    width: 40% !important;
  }
}
/* Hero Section */
.hero-section {
  position: relative;
  height: 70vh;
  background-image: linear-gradient(to right, #5cb8b2, #9de7d7);
}
.hero-section .container {
  position: relative;
  z-index: 2;
  height: 100%;
}
.hero-title {
  text-transform: uppercase;
  margin-bottom: 24px;
  color: #fff !important;
}
.hero-subtitle {
  font-size: 1.5rem !important;
  margin-bottom: 34px !important;
  color: #5c5c5c !important;
}
.hero-btn {
  margin: 0px 32px;
  background-color: #fff !important;
  border-color: var(--primary-color) !important;
  color: var(--fourth-color) !important;
  border-radius: 25px !important;
  padding: 10px 15px !important;
  max-width: 350px !important;
  font-weight: 700 !important;
}
.hero-btn:hover {
  background-color: var(--fourth-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff !important;
  font-weight: 700 !important;
}
@media (max-width: 767.98px) {
  .hero-section {
    height: 110vh !important;
  }
}
@media (max-width: 575.98px) {
  .hero-section {
    height: 80vh !important;
  }
}
/* Banner */
.wrapper-banner .img-fluid {
  width: 100% !important;
  object-fit: cover !important;
  background-repeat: no-repeat !important;
}
.banner-footer {
  position: relative;
  margin-top: -12 0px !important;
  z-index: -5 !important;
  width: 100% !important;
  object-fit: cover !important;
  background-repeat: no-repeat !important;
}
@media (max-width: 991.98px) {
  .banner-footer {
    margin-top: -50px !important;
  }
}
@media (max-width: 575.98px) {
  .banner-footer {
    margin-top: 0px !important;
  }
}
/* Mission */
.mission-title {
  margin-bottom: 20px !important;
  text-align: start !important;
}
/* Patients*/
.patient-card {
  border: 1px solid #9de7d7 !important;
  height: 100% !important;
  border-radius: 25px !important;
  box-shadow: 0 8px 16px rgba(157, 231, 215, 0.8) !important;
  padding: 30px 20px;
}
.patient-card h4 {
  font-weight: 600 !important;
}
.circle-number {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background-color: #5CB8B2 !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  font-weight: 900;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.circle-number span {
  font-size: 1.5rem;
}
/* Professional */
.professional-card h4 {
  font-weight: 600 !important;
}
/* Testimonials */
.card-testimonial {
  padding: 14px 7px !important;
  border-radius: 25px !important;
  background-color: #f8f9f9 !important; /* #C6F9ED */
  border: none !important;
  min-height: 700px;
  flex: 1; 
  max-width: 50%;
}

@media (max-width: 991.98px) {
  .card-testimonial {
    max-width: 100% !important;
  }
}
/* Pricing */
.pricing-toggle.active {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border-color: var(--primary-color) !important;
}
.pricing-toggle {
  background-color: #fff !important;
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.pricing-card {
  border: 1px solid #9de7d7 !important;
  padding: 14px 7px !important;
  border-radius: 25px !important;
  box-shadow: 0 8px 16px rgba(157, 231, 215, 0.8) !important;
}
.pricing-card h4 {
  font-weight: 600 !important;
}
.pricing-card ul > li {
  margin-bottom: 10px !important;
}
.pricing-card ul > li::marker {
  color: var(--primary-color) !important;
}
.pricing-card .btn-prim {
  font-size: 1rem !important;
}
.pricing-card svg {
  color: var(--primary-color) !important;
}
/* Contact */
#contacto {
  background-color: #57ada7 !important;
}
#contacto .section-title h2,
#contacto .section-title h3 {
  color: #fff !important;
}
.contact-card {
  border-radius: 25px !important;
  padding: 14px 7px !important;
}
.form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.contact-card .btn-prim {
  font-size: 1rem !important;
}
/* Info Pages */
.info-pages {
  padding: 75px 0px 0px 0px !important;
}

.pricingComparison {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}

.pricing-comparison-wrapper {
  transition: opacity 0.5s ease, max-height 0.5s ease;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  background-color: #D3F1E6;
  padding: 15px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(157, 231, 215, 0.8) !important;
}

.pricing-comparison-wrapper.show {
  opacity: 1; 
  max-height: 2000px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 100px;
}

.pricing-comparison-wrapper.hide {
  opacity: 0;
  max-height: 0; 
}

@media (max-width: 991.98px) {
  .pricing-comparison-wrapper {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .pricingComparison {
    flex-wrap: nowrap !important;
    display: flex;
    margin-top: 30px !important;
    height: auto !important;
    padding-top: 0px !important;
  }
  .pricingComparison .col {
    flex: 0 0 auto;
  }
}



.list-features {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding: 0px !important;
  border: 1.5px solid var(--primary-color) !important;
  background-color: #fff !important;
  border-radius: 25px !important;
  z-index: 6;
  overflow: hidden;
}

.list-features > * {
  word-break: break-word;
  white-space: normal;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  /* min-inline-size: max-content; */
  border-bottom: 1px solid var(--primary-color);
  background-color: #fff !important;
  margin: 0px; 
  color: var(--fourth-color) !important;
  align-content: center;
}

.list-features > div {
  align-items: center;
  word-break: break-word;
  flex: 1; 
  font-family: "Kodchasan", sans-serif !important;
  font-weight: bold;
  background-color: #fff !important;
  margin-bottom: 0px !important;
  padding: 10px 15px;
}

.list-features > div > span {
  font-weight: normal !important;
}

.list-features > div > svg, .x-feature {
  color: var(--primary-color) !important;
}

.list-features > *:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.title-plan-comparison {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #37BA83;
  width: 70%;
  margin: 0 auto;
  font-family: "Kodchasan", sans-serif !important;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
}

.price-plan-comparison {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #37BA83;
  font-family: "Kodchasan", sans-serif !important;
  color: #fff;
  padding: 30px 10px 10px 10px;
  position: relative;
  top: -25px;
  z-index: 5;
  font-weight: bold;
}

.strike-text {
  text-decoration: line-through;
}

.bigger-text {
  font-size: 1.2rem;
}

.hide-content {
  visibility: hidden;
}

.hubspotForm.hidden {
  display: none;
}

@media (max-width: 991.98px) {
  .fixedModal {
    width: 90% !important;
  }
}

.fixedModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 90%;
  z-index: 9999;
  display: none;
  background-color: #fff;
  border: 1.5px solid var(--primary-color) !important;
  border-radius: 25px !important;
  box-shadow: 0 8px 16px rgba(157, 231, 215, 0.8) !important;
  padding: 5px 15px;
  overflow-y: auto;
}

.closeModalBtn {
  position: fixed;
  right: 25px;
  cursor: pointer;
}

.font-button {
  font-family: "Kodchasan", sans-serif !important;
  font-weight: bold;
}

.div-testimonial {
  font-family: "Kodchasan", sans-serif !important;
}

.text-testimonal {
  font-family: "Poppins", sans-serif !important;
  font-size: 0.85rem !important;
}

@media (max-width: 991.98px) {
  .text-testimonal {
    font-size: 0.8rem !important;
  }
}


.icons-footer {
  color: var(--fourth-color) !important;
  display: flex;
  gap: 20px;
  margin-top: 5px;
}